import { useEffect, useState } from 'react'
import { Button, Col, Pagination, Spinner, Table } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { getPlayers } from '../API'

const defaultPageSize = 30

export const Players = () => {
    const today = new Date().toISOString().split('T')[0]
    const twoMonthsAgo = new Date(new Date().setMonth(new Date().getMonth() - 2)).toISOString().split('T')[0]

    const defaultFilters = {
        page: 1,
        pageSize: 30,
        startDate: twoMonthsAgo,
        endDate: today,
        createdInPromo: true
    }

    const [loading, setLoading] = useState(false)
    const [players, setPlayers] = useState([])

    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const [filters, setFilters] = useState({
        page: 1,
        pageSize: 30,
        startDate: twoMonthsAgo,
        endDate: today,
        createdInPromo: true
    })

    const fetchPlayers = async ({ page = 1, pageSize = 15, startDate = filters.startDate, endDate = filters.endDate, createdInPromo = false }) => {
        setLoading(true)
        const res = await getPlayers({ page, pageSize, startDate, endDate, createdInPromo })
        if (res?.data) {
            setPlayers(res.data)
            setTotalPages(res.totalPages)
            setCurrentPage(res.currentPage)
        }
        setLoading(false)
    }

    const handleSearch = () => {

        if (filters.startDate > filters.endDate) {
            Swal.fire({
                icon: 'error',
                text: 'La fecha de inicio no puede ser mayor a la fecha de fin'
            })
            return;
        }

        const startDate = new Date(filters.startDate);
        const endDate = new Date(filters.endDate);
        const diffYears = endDate.getFullYear() - startDate.getFullYear();
        const diffMonths = diffYears * 12 + (endDate.getMonth() - startDate.getMonth());
        console.log(diffMonths, 'diffMonths')
        if (diffMonths > 2) {
            Swal.fire({
                icon: 'error',
                text: 'La diferencia entre fecha de inicio y fecha de fin no puede ser mayor a 2 meses'
            })
            return;
        }


        fetchPlayers({ ...filters, page: 1, pageSize: defaultPageSize })
    }



    const cleanFilters = () => {
        setFilters({ ...defaultFilters })
        fetchPlayers({ ...defaultFilters })
    }


    const handlePageChange = (page) => {
        setCurrentPage(page)
        fetchPlayers({ ...filters, page, pageSize: defaultPageSize })
    }



    useEffect(() => {
        fetchPlayers({ ...defaultFilters })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    const generatePaginationItems = () => {
        let items = [];
        let startPage = Math.max(1, currentPage - 5);
        let endPage = Math.min(totalPages, currentPage + 5);

        if (startPage !== 1) {
            items.push(
                <Pagination.Item key="first" onClick={() => handlePageChange(1)}>
                    1
                </Pagination.Item>
            );

            items.push(<Pagination.Ellipsis key="ellipsis1" />);
        }

        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>,
            );
        }


        if (endPage !== totalPages) {
            items.push(<Pagination.Ellipsis key="ellipsis2" />);
            items.push(
                <Pagination.Item key="last" onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );

        }

        return items;
    };



    return (
        <div className='pb-5 mt-4'>
            <Col md={12} sm={12} className='mx-auto'>

                <div className='mb-3 mt-5'>
                    <div className="d-flex align-items-end justify-content-between flex-wrap gap-3">
                        <div className="d-flex gap-4 align-items-end">
                            <div className='d-flex gap-2'>
                                <div className="d-flex flex-column align-items-start">
                                    <label htmlFor="start"><small>Fecha inicio</small></label>
                                    <input value={filters.startDate} type="date"
                                        id="nacimiento" name="nacimiento"
                                        className="form-control customDarkDate text-white"
                                        onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                                    />
                                </div>
                                <div className="d-flex flex-column align-items-start">
                                    <label htmlFor="end"><small>Fecha fin</small></label>
                                    <input value={filters.endDate} type="date"
                                        id="nacimiento" name="nacimiento"
                                        className="form-control customDarkDate text-white"
                                        onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}

                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex gap-2 align-items-end justify-content-end ">
                                <Button
                                    variant='success'
                                    onClick={handleSearch}
                                    className='text-nowrap mx-2'
                                >
                                    Buscar
                                </Button>
                                <Button
                                    variant='dark'
                                    onClick={cleanFilters}
                                    className='text-nowrap'
                                >
                                    Limpiar filtros
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>

                <div>
                    {loading &&
                        <div className='d-flex justify-content-center align-items-center flex-column text-primaryy' style={{ height: '20vh' }}>
                            <div className="mb-1">
                                Cargando página {currentPage}
                            </div>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    }
                    {!loading && <div>
                        <Table variant="dark">
                            <thead className="customHead">
                                <tr className='text-secondary customHead'>
                                    <th>Usuario</th>
                                    <th>Nombre Completo</th>
                                    <th>Teléfono</th>
                                </tr>
                            </thead>
                            <tbody>

                                {players?.map((item, index) => (
                                    <tr className='text-white customRow' key={item.id}>
                                        <td>{item.platformUser}</td>
                                        <td>{item.firstName + ' ' + item.lastName}</td>
                                        <td>{item.phone ?? ''}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </Table>
                        <div className='d-flex justify-content-center'>
                            <div className='d-flex justify-content-center'>
                                <Pagination className="custom-pagination">{generatePaginationItems()}</Pagination>
                            </div>
                        </div>
                    </div>}
                </div>
            </Col>
        </div >

    )
}
