import React, { useEffect, useState } from 'react';
import { Modal, Button, Toast } from 'react-bootstrap';
import { getApiConfig } from '../../../../API/getConfig';
import axios from 'axios';
import Swal from 'sweetalert2';

export const CompletePlayerInfoModal = ({ show, handleClose, playerInfo }) => {
    const [loading, setLoading] = useState(false)
    const [playerForm, setPlayerForm] = useState({
        phone: '',
        platformUser: playerInfo?.platformUser,
        firstName: '',
        lastName: '',
        playerId: playerInfo?.id,
    })

    const inputhandler = (e) => {
        setPlayerForm({ ...playerForm, [e.target.name]: e.target.value })
    }

    const submit = async () => {
        setLoading(true)
        try {
            const config = getApiConfig()
            const response = await axios.patch('/updateUserInPromo', playerForm, config)
            Swal.fire({
                text: 'Hecho.',
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 500
            })
            
            handleClose()
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }

    }

    useEffect(() => {
        setPlayerForm({ ...playerForm, platformUser: playerInfo?.platformUser ?? '' })
    }, [playerInfo])


    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>


            <Modal.Body>
                <Modal.Title>Completa Información Requerida</Modal.Title>
                <div className="mt-4">
                    <p>El usuario <span className='text-success'>{playerInfo?.platformUser}</span> fue creado en <span className='text-success'>Promoción</span> y no tiene un número de teléfono asignado. Debes asignarlo.</p>
                </div>

                <div className="mb-2">
                    <label htmlFor="phone">Número de teléfono <span className='text-danger'>*</span></label>
                    <input value={playerForm.phone} onChange={inputhandler} name='phone' type="text" className="form-control mx-0 customDarkInput" placeholder="Teléfono" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                </div>

                <div className="mb-2">
                    <label htmlFor="firstName">Nombre</label>
                    <input value={playerForm.firstName} onChange={inputhandler} name='firstName' type="text" className="form-control mx-0 customDarkInput" placeholder="Nombre" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                </div>

                <div className="mb-4">
                    <label htmlFor="lastName">Apellido</label>
                    <input value={playerForm.lastName} onChange={inputhandler} name='lastName' type="text" className="form-control mx-0 customDarkInput" placeholder="Apellido" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                </div>

            </Modal.Body>
            <Button variant="primary" disabled={loading || playerForm.phone.length < 5} onClick={submit}>
                {loading ? 'Guardando...' : 'Guardar'}
            </Button>
        </Modal>

    );
}
