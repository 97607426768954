import React, { useState, useEffect } from 'react'
import { getSupervisorInfo, getSupervisorUsers, toggleBlockUser } from './API/API'
import Cookies from 'js-cookie';
import { MdLogout, MdNavigateNext, MdOutlineMoreVert, MdRefresh } from 'react-icons/md';
import { TbUsers } from "react-icons/tb";
import './Supervisor.css'
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import { FaLock, FaUnlock } from "react-icons/fa";

export const Supervisor = () => {

    const [supervisorInfo, setSupervisorInfo] = useState(null)
    const [lastUpdate, setLastUpdate] = useState(null)

    // Table
    const [supervisorUsers, setSupervisorUsers] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [noMoreRecords, setNoMoreRecords] = useState(false)
    const [totalPages, setTotalPages] = useState([])
    const loading = false



    const handlePageChange = (page) => {
        setCurrentPage(page)
        fetchSupervisorUsers(page)
    }

    const handleLogout = () => {
        Cookies.remove('Token');
        window.location.href = '/';
    }

    const fetchSupervisorInfo = async () => {
        const res = await getSupervisorInfo()
        if (res?.data?.role !== 'supervisor') {
            handleLogout()
        } else {
            setSupervisorInfo(res.data)
        }
    }

    const fetchSupervisorUsers = async (page) => {
        const res = await getSupervisorUsers(page)
        setSupervisorUsers(res?.data?.data)
        setCurrentPage(res?.data?.currentPage)
        setNoMoreRecords(res?.data?.totalPages <= res?.data?.currentPage)
        setTotalPages(Array.from({ length: res?.data?.totalPages }, (_, i) => i + 1))
        setLastUpdate(new Date().toLocaleString('es-AR', { timeZone: 'America/Argentina/Buenos_Aires' }))
    }



    const handleToggleBlockUser = async (user) => {
        try {
            const res = await toggleBlockUser(user?.id)
            console.log(res, '213')
            if (res?.data?.msg === 'Usuario actualizado correctamente') {
                fetchSupervisorUsers(currentPage)
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Hubo un error al actualizar el usuario',
                icon: 'error',
            })
            console.log(error)
        }
    }

    const confirmToggleBlockuser = async (user) => {
        if (!user) return;

        const blockedText = `Estás por bloquear al usuario ${user?.name}. Al confirmar, el usuario será expulsado de la plataforma y no podrá volver a ingresar.`
        const unblockedText = `Estás por desbloquear al usuario ${user?.name}. Al confirmar, el usuario podrá volver a ingresar a la plataforma y trabajar normalmente.`

        Swal.fire({
            title: `¿${user?.blocked ? 'Desbloquear' : 'Bloquear'} ${user?.name}?`,
            text: user?.blocked ? unblockedText : blockedText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `${user?.blocked ? 'Si, desbloquear' : 'Bloquear'}`,
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                handleToggleBlockUser(user);
            } else {
                return;
            }
        });
    }

    useEffect(() => {
        fetchSupervisorInfo()
        fetchSupervisorUsers(currentPage)
    }, [])


    const getStatus = (entry) => {
        const fiveMinutesAgo = new Date(new Date().getTime() - 5 * 60000);
        if ((new Date(entry?.lastActionDate) > fiveMinutesAgo || new Date(entry?.loginDate).toLocaleString('es-AR') > fiveMinutesAgo)) {
            return { color: 'text-green', text: 'Activo' }
        } else {
            return { color: 'text-danger', text: 'Inactivo' }
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSupervisorUsers(currentPage);
        }, 300000);
        return () => clearInterval(interval);
    }, []);


    return (
        <div className='DashboardSupervisor d-flex flex-column items-center'>
            <div className=''>
                <div className='d-flex justify-content-between w-100 gap-5 align-items-center mb-4 w-50 m-auto rounded py-1'>

                    <div className="darkBackground d-flex p-1 d-flex justify-content-end px-2 align-items-center primary-green">
                        <span className=' d-flex align-items-center text-center' style={{ fontSize: '14px' }}>
                            <TbUsers className='mx-1 text-white greem-hover' size={16}></TbUsers> {supervisorInfo?.name}
                        </span>
                    </div>

                    <div className="darkBackground d-flex p-1 d-flex justify-content-end text-white">
                        <div className='d-flex align-items-center btn py-0 greem-hover' style={{ fontSize: '12px' }} onClick={handleLogout}>
                            Cerrar sesión <MdNavigateNext className='mx-2 text-white  greem-hover' size={16}></MdNavigateNext>
                        </div>
                    </div>
                </div>
                <div className="border border-primary-green py-1 rounded mt-2 text-white">
                    Panel Supervisor
                </div>
            </div>


            <div className="mt-5">
                <div className='d-flex justify-content-end'>
                    <div className='d-flex align-items-center text-white mx-3'>
                        <div className='mx-3 text-secondary'>
                            Última Actualización: {lastUpdate}
                        </div>
                        <button className='btn btn-secondary d-flex align-items-center' onClick={() => fetchSupervisorUsers(currentPage)}>
                            Actualizar
                        </button>
                    </div>
                </div>
                <div className="text-xs" style={{ minWidth: '1500px' }} role="document">
                    <div className="text-white">
                        {supervisorUsers?.length > 0 ?
                            <div className="modal-body" style={{ fontSize: '12px' }}>
                                <div style={{ overflowX: 'auto' }}>
                                    <table className="table">
                                        <thead className='text-secondary' >
                                            <tr>
                                                <th>Usuario</th>
                                                <th>Administrador</th>
                                                <th>Último Ingreso</th>
                                                <th>Última Interacción</th>
                                                <th>Estado</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {supervisorUsers?.map((entry, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            {entry?.name}
                                                            <div className='d-flex align-items-center mx-2'>
                                                                {entry?.blocked ? <FaLock className='text-danger' /> : ''}
                                                            </div>
                                                        </div>

                                                    </td>
                                                    <td>{entry?.Owner?.name}</td>
                                                    <td>{entry?.loginDate ? new Date(entry?.loginDate).toLocaleString("es-AR", { timeZone: "America/Argentina/Buenos_Aires", hour12: false }) : '-'}</td>
                                                    <td>{entry?.lastActionDate ? new Date(entry?.lastActionDate).toLocaleString("es-AR", { timeZone: "America/Argentina/Buenos_Aires", hour12: false }) : '-'}</td>
                                                    <td className={getStatus(entry)?.color}>
                                                        {getStatus(entry)?.text}
                                                    </td>
                                                    <td className='d-flex justify-content-center'>
                                                        {entry.blocked ?
                                                            <div title='Desbloquear' className='d-flex align-items-center text-success' role='button' onClick={() => confirmToggleBlockuser(entry)} style={{ cursor: 'pointer' }}>
                                                                <FaUnlock />
                                                                <span className='mx-2'>Desbloquear</span>
                                                            </div>
                                                            :
                                                            <div title='Bloquear' className='d-flex align-items-center text-danger' role='button' onClick={() => confirmToggleBlockuser(entry)} style={{ cursor: 'pointer' }}>
                                                                <FaLock />
                                                                <span className='mx-2'>Bloquear</span>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex justify-content-center mb-3 ">
                                    <div>
                                        <button className='p-0 px-4 mx-2 text-start btn btn-dark' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage <= 1}>Anterior</button>
                                    </div>
                                    <div>
                                        <select className='form-control customDarkInput' onChange={(e) => handlePageChange(e.target.value)} value={currentPage}>
                                            {totalPages.map((page) => (
                                                <option className='text-white' key={page} value={page}>{page}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <button disabled={noMoreRecords || loading} className='p-0 px-4 mx-2 text-start  btn btn-dark' onClick={() => handlePageChange(currentPage + 1)}>Siguiente</button>
                                    </div>
                                </div>

                            </div>

                            :

                            !loading ?
                                <div className='text-white py-5'>
                                    No hay más registros
                                    <div className='mt-3'>
                                        <button onClick={() => ''} className='p-0 px-4 mx-2 text-start btn btn-dark'>Buscar de nuevo</button>
                                    </div>
                                </div>
                                :
                                <div className='text-small my-5 py-5'>
                                    Cargando..
                                </div>

                        }


                    </div>


                </div>
            </div>

        </div>
    )
}

