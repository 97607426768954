import axios from 'axios'
import { getApiConfig } from './getConfig';
// const adminName = process.env.REACT_APP_CAJERO
const adminName = localStorage.getItem('ownerName');

export const createNotification = async (body) => {
    let config = getApiConfig();

    let payload = {
        ...body,
        ownerName: adminName
    }

    try {
        const response = await axios.post(`/admin/createNotification`, payload, config);
        return response;
    } catch (error) {
        return error
    }
};

export const deleteNotification = async (notificationId) => {
    let config = getApiConfig();

    try {
        const response = await axios.delete(`/admin/deleteNotificationById?notificationId=${notificationId}`, config);
        return response;
    } catch (error) {
        return error
    }
};



export const deleteBotUser = async (userId) => {
    let config = getApiConfig();

    let payload = {
        ownerName: adminName,
        userId: userId
    }

    try {
        const response = await axios.post(`/admin/deleteBotUser`, payload, config);
        return response;
    } catch (error) {
        return error
    }
};

export const postAuth = async (body) => {
    let config = getApiConfig();

    let payload = {
        ...body,
        // ownerName: adminName,
    }

    try {
        const response = await axios.post(`/authBotUser`, payload, config);
        return response;
    } catch (error) {
        return error
    }
};

export const postAdminAuth = async (body) => {
    let config = getApiConfig();

    let payload = {
        ...body,
        // ownerName: adminName,
    }

    try {
        const response = await axios.post(`/authAdminUser`, payload, config);
        return response;
    } catch (error) {
        return error
    }
};


export const patchUser = async (body) => {
    let config = getApiConfig();

    let payload = {
        ...body,
        ownerName: adminName,
    }

    try {
        const response = await axios.post(`/admin/patchBotUser`, payload, config);
        return response;
    } catch (error) {
        return error
    }

}


export const createNewUser = async (body) => {
    let config = getApiConfig();

    let payload = {
        ...body,
        ownerName: adminName,
    }

    try {
        const response = await axios.post(`/admin/createBotUser`, payload, config);
        return response;
    } catch (error) {
        return error
    }
}

export const checkValidOwner = async (adminName) => {
    let config = getApiConfig();
    let payload = {
        adminName: adminName,
    }
    try {
        const response = await axios.post('/checkValidOwner', payload, config)
        return response;
    } catch (error) {
        return error
    }
}



export const testUserToken = async () => {
    const config = getApiConfig()
    try {
        let result = await axios.post(`/testToken`, {}, config)
        return result;
    } catch (error) {
        console.log(error)
        return error
    }
}


export const updateAdminInfo = async (payload) => {
    const config = getApiConfig()
    try {
        let result = await axios.post(`/admin/updateAdmin`, payload, config)
        return result.data;
    } catch (error) {
        console.log(error)
        return error
    }
}
export const getPaymentScaleUsers = async () => {
    let config = getApiConfig()
    let result;
    try {
        await axios.get(`/getPaymentScaleUser`, config)
            .then((res) => {
                result = res?.data ?? '';
            })
    } catch (error) {
        console.log(error)
    }

    return result
}

export const postTrack = async () => {
    const config = getApiConfig()
    try {
        let result = await axios.post(`/activity/track`, {}, config)
        return result;
    } catch (error) {
        console.log(error)
        return error
    }
}

export const closeSession = async () => {
    const config = getApiConfig();
    try {
        let result = await axios.post(`/closeSession`, {}, config);
        return result;
    } catch (error) {
        console.log(error);
        return error;
    }
}


export const fetchBalance = async () => {
    let config = getApiConfig()
    let result;
    try {
        await axios.get(`/balance`, config)
            .then((res) => {
                result = res?.data ?? '';
            })
    } catch (error) {
        console.log(error)
    }

    return result
}
