import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios'
import { createRoot } from 'react-dom/client';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

axios.defaults.baseURL = process.env.REACT_APP_API || "http://localhost:3001"
// axios.defaults.baseURL = process.env.REACT_APP_API || "https://api22.onrender.com"


axios.interceptors.response.use(
  (response) => {
    // Procesar la respuesta (si es necesario)
    return response;
  },
  (error) => {
    // Manejar errores globales
    // if (error.response?.status === 403) {
    //   Swal.fire({ icon: 'warning', text: 'Esta sesión ha caducado. Ingresa de nuevo para seguir operando.' });
    //   window.location.reload();
    // }
    if (error.response?.status === 403 && error?.response?.data?.msg === 'Usuario Bloqueado.') {
      Cookies.remove("Token")
      localStorage.removeItem('loggedUser')
      localStorage.removeItem('userLocalPw')
      Swal.fire({ icon: 'warning', text: 'Usuario Bloqueado. Contacte con el administrador para desbloquearlo.' });
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,);

reportWebVitals();
