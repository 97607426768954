import React, { useEffect, useState } from 'react'
import { RiUserSettingsLine } from 'react-icons/ri'
import { Card, Form } from 'react-bootstrap';
import '../Settings.css'
import { updateAdminInfo } from '../../../../API/API';
import Swal from 'sweetalert2'
import { CustomSwitch } from '../../../customComponents/CustomSwitch';
import { BiParty } from 'react-icons/bi';

export const BotConfig = ({ adminData }) => {
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [errors, setErrors] = useState('');

    const [formData, setFormData] = useState({
        newName: '',
        firstLetter: '',
        password: '',
        newPassword: '',
        password_repeat: '',
        default_users_password: '',
        pageLink: '',
        useMaxLoadValue: false,
        maxLoadValue: 0,
        showBalance: false,
        currentlyInPromo: false
    });



    useEffect(() => {
        if (adminData) {
            setFormData({
                ...formData,
                newName: adminData?.name,
                firstLetter: adminData?.firstLetter,
                default_users_password: adminData?.default_users_password,
                pageLink: adminData?.pageLink,
                useMaxLoadValue: adminData?.useMaxLoadValue,
                maxLoadValue: adminData?.maxLoadValue ? String(adminData?.maxLoadValue) : 0,
                showBalance: adminData?.showBalance,
                currentlyInPromo: adminData?.currentlyInPromo
            })
        }




    }, [adminData])

    const handleCancelChangePw = () => {
        setShowChangePassword(false);
        setFormData({ ...formData, password: '', newPassword: '', password_repeat: '' })
    }

    const handleInputValues = (e) => {
        if (e.target.name === 'maxLoadValue') {
            if (Number(e.target.value) > 10000000) {
                setFormData({
                    ...formData,
                    [e.target.name]: 1000000
                })
                return;
            }
        }
        setErrors('')
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const submitForm = async () => {
        const payload = {
            newName: formData.newName,
            firstLetter: formData.firstLetter,
            default_users_password: formData.default_users_password,
            pageLink: formData.pageLink,
            useMaxLoadValue: formData.useMaxLoadValue,
            maxLoadValue: formData.maxLoadValue ? formData.maxLoadValue?.replace(',', '')?.replace('.', '') : 0,
            showBalance: formData.showBalance,
            currentlyInPromo: formData.currentlyInPromo
        }



        if (formData.firstLetter.length > 2) {
            setErrors('Las primeras letras deben ser máximo 2.')
            return;
        };

        if (formData.newPassword && formData.newPassword.length < 6) {
            setErrors('La nueva contraseña debe ser de al menos 6 caracteres')
            return;
        };

        if (formData.newPassword) {
            if (formData.password_repeat !== formData.newPassword) {
                setErrors('Las contraseñas no coinciden')
                return;
            }
            payload.newPassword = formData.newPassword
            payload.password = formData.password
        }

        if (formData.default_users_password && (adminData?.platform === 'pulpobet')) {
            if (formData.default_users_password.length < 4 || formData.default_users_password.length > 16) {
                setErrors('La contraseña debe contener entre 4 y 16 caracteres.');
                return;
            }
            const hasNumberOrUppercase = /[0-9]/.test(formData.default_users_password) && /[A-Z]/.test(formData.default_users_password);
            if (!hasNumberOrUppercase) {
                setErrors('La contraseña debe tener un número y una letra en mayúscula.');
                return;
            }
            const hasLetter = /[A-Za-z]/.test(formData.default_users_password);
            if (!hasLetter) {
                setErrors('La contraseña debe contener al menos una letra.');
                return;
            }
        }

        if (formData.default_users_password) {
            if (formData.default_users_password.length < 6 || formData.default_users_password.length > 16) {
                setErrors('La contraseña debe contener entre 6 y 16 caracteres.');
                return;
            }
            const hasNumberOrUppercase = /[0-9]/.test(formData.default_users_password) || /[A-Z]/.test(formData.default_users_password);
            if (!hasNumberOrUppercase) {
                setErrors('La contraseña debe tener al menos un número o una letra en mayúscula.');
                return;
            }
            const hasLetter = /[A-Za-z]/.test(formData.default_users_password);
            if (!hasLetter) {
                setErrors('La contraseña debe contener al menos una letra.');
                return;
            }
        }


        let apiResponse = await updateAdminInfo(payload);

        if (apiResponse?.response?.data?.msg === 'Bad password') {
            setErrors('Contraseña actual incorrecta.')
            return;
        };

        if (apiResponse?.response?.status === 400) {
            setErrors('Ha habido un error. Intenta de nuevo')
            return;
        };


        Swal.fire({ text: `Actualizado correctamente` });

        setFormData({
            ...formData,
            password: '',
            newPassword: '',
            password_repeat: '',
        })
        setErrors('')
        setShowChangePassword(false);
    }

    const handleSwitchChange = (checked) => {
        setFormData({ ...formData, useMaxLoadValue: checked });
    };

    const handleShowBalanceChange = (checked) => {
        setFormData({ ...formData, showBalance: checked });
    };

    const handleCurrentlyInPromoChange = (checked) => {
        setFormData({ ...formData, currentlyInPromo: checked });
    };

    const idsWithPromo = [
        '89bb9b1f-6fc5-46b1-be93-4fadc000ae85',
        '9c313574-36f3-4c15-9257-651800d5905e',
        '16d506f-e5a3-4224-96ed-518765df73be',
        '4369f213-a3b5-483e-8b2b-2b18123d72ed',
        'c4ad738a-0f15-48a5-810b-ebadc3ea7560',
        '7aa0f00a-f48a-4903-93c0-5dd1e201466a',
    ];
    const showPromoButtonOption = idsWithPromo.includes(adminData?.id);

    return (
        <Card className='bg-transparent'>


            <Card.Body className='settingsCard'>
                <Card.Title className='d-flex align-items-center justify-content-center'>
                    <RiUserSettingsLine></RiUserSettingsLine>
                    <span className='mx-2'>Configuración del <b>BOT</b></span>
                </Card.Title>
                <span className='text-secondary mx-2' style={{ fontSize: '12px' }}>Aquí configurarás todo lo relacionado al administrador del bot, como su nombre, su contraseña, y la primer letra que será usada para crear nuevos usuarios.</span>

                <label className='d-flex justify-content-start'>Primera letra nuevos usuarios</label>
                <input maxLength={2} name='firstLetter' onChange={handleInputValues} className='form-control customDarkInput rounded-md text-white' value={formData.firstLetter}></input>
                <span className='text-secondary d-flex justify-content-start text-start my-1 mx-1' style={{ fontSize: '10px' }}>
                    *Letra/s con la que comenzarán todos los usuarios creados por el bot, con formato <b className='mx-1'>Letra + Nombre + Numero </b>. Ejemplo: <b className='mx-1'>MAgustin53</b>
                </span>
                <label className='d-flex justify-content-start'>Contraseña de usuarios por defecto</label>
                <input maxLength={16} name='default_users_password' onChange={handleInputValues} className='form-control customDarkInput rounded-md text-white' value={formData.default_users_password}></input>
                <span className='text-secondary d-flex justify-content-start text-start my-1 mx-1' style={{ fontSize: '10px' }}>
                    *Contraseña que se usará por defecto al CREAR o RESTAURAR usuarios. Por defecto es "hola123".
                </span>
                <label className='d-flex justify-content-start'>Link de la página</label>
                <input maxLength={100} name='pageLink' onChange={handleInputValues} className='form-control customDarkInput rounded-md text-white' value={formData.pageLink}></input>
                <span className='text-secondary d-flex justify-content-start text-start my-1 mx-1' style={{ fontSize: '10px' }}>
                    *Puedes utilizar este campo para guardar la URL que los jugadores utilizan para ingresar a la plataforma. Se utilizará con la variable __LINK__.
                </span>


                <div>
                    <div className='d-flex align-items-center mt-2 rounded pt-2'>
                        <label className='d-flex justify-content-start mr-2 mt-1'>Confirmación de carga</label>
                        <div className="mx-2"><CustomSwitch checked={formData.useMaxLoadValue} onChange={handleSwitchChange} /></div>
                    </div>
                    {formData.useMaxLoadValue &&
                        <>
                            <input maxLength={10}
                                name='maxLoadValue'
                                max={1000000} type='number'
                                onChange={handleInputValues}
                                className='form-control customDarkInput rounded-md text-white'
                                value={formData.maxLoadValue}>
                            </input>
                        </>

                    }
                    <span className='text-secondary d-flex justify-content-start text-start mb-1 mx-1' style={{ fontSize: '10px' }}>
                        *Si está activado, el bot enviará una alerta de confirmación si un usuario intenta cargar más del valor configurado.
                    </span>
                </div>
                <div>
                    <div className='d-flex align-items-center mt-2 rounded pt-2'>
                        <label className='d-flex justify-content-start mr-2 mt-1'>Mostrar balance</label>
                        <div className="mx-2"><CustomSwitch checked={formData.showBalance} onChange={handleShowBalanceChange} /></div>
                    </div>
                    <span className='text-secondary d-flex justify-content-start text-start mb-1 mx-1' style={{ fontSize: '10px' }}>
                        *Si está activado, se mostrará el balance total de la plataforma a los usuarios.
                    </span>
                </div>

                {showPromoButtonOption && <div>
                    <div className='d-flex align-items-center mt-2 rounded pt-2'>
                        <label className='d-flex justify-content-start mr-2 mt-1 align-items-center'>Activar Promoción</label>
                        <div className="mx-2"><CustomSwitch checked={formData.currentlyInPromo} onChange={handleCurrentlyInPromoChange} /></div>
                    </div>
                    <span className='text-secondary d-flex justify-content-start text-start mb-1 mx-1' style={{ fontSize: '10px' }}>
                        * Si está activado, los operarios podrán crear usuarios marcados como "Creados en Promoción"
                    </span>
                </div>}

                <br />
                <label className='d-flex justify-content-start mt-3'>Nombre</label>

                <input disabled name='newName' onChange={handleInputValues} className='form-control customDarkInput rounded-md text-white' value={formData.newName}></input>
                <br />



                {showChangePassword &&
                    <>
                        <label className='d-flex justify-content-start align-items-center'>Contraseña administrador actual <span className="text-danger mx-1">*</span></label>
                        <input autoComplete="off" name='password' onChange={handleInputValues} type='password' className='form-control customDarkInput text-white rounded-md' value={formData.password}></input>
                        <br />

                        <label className='d-flex justify-content-start align-items-center'>Nueva contraseña <span className="text-danger mx-1">*</span><span className='text-secondary' style={{ fontSize: '12px' }}>Mínimo 6 caracteres.</span></label>
                        <input autoComplete="off" name='newPassword' onChange={handleInputValues} type='password' className='form-control customDarkInput rounded-md text-white' value={formData.newPassword}></input>
                        <br />

                        <label className='d-flex justify-content-start '>Repetir nueva contraseña <span className="text-danger mx-1">*</span></label>
                        <input autoComplete="off" name='password_repeat' onChange={handleInputValues} type='password' className='form-control customDarkInput rounded-md text-white' value={formData.password_repeat}></input>
                    </>
                }

                <span className='text-danger'><small>{errors && errors}</small></span>

                <div className='d-flex justify-content-between mt-5'>
                    {!showChangePassword && <button className='btn btn-green-outline text-white' onClick={() => setShowChangePassword(true)}>Cambiar contraseña</button>}
                    {showChangePassword && <button className='btn btn-green-outline text-white' onClick={handleCancelChangePw}>Cancelar</button>}
                    <button className='btn btn-green px-4' onClick={submitForm}>Guardar</button>
                </div>
            </Card.Body>
        </Card>
    )
}
