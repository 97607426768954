import React, { useEffect, useRef } from 'react'
import './NewUser.css';
import axios from 'axios'
import { useState } from 'react';
import Swal from 'sweetalert2'
import { createUser_success, unblock_success, resetpw_success, cloneUser_success } from '../../../assets/data';
import unlock from '../../../assets/unlock.png'
import { Col, Row, Spinner, Dropdown } from 'react-bootstrap';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FiUnlock, FiRefreshCcw, FiUsers } from 'react-icons/fi'
import { BsPersonAdd, BsPersonFillAdd } from 'react-icons/bs'
import { BiGift, BiLoaderCircle } from 'react-icons/bi'
import { MdOutlineHistoryToggleOff } from 'react-icons/md'
import GameHistoryModal from './GameHistoryModal';
import { Link, useNavigate } from 'react-router-dom';
import { getApiConfig } from '../../../API/getConfig';
import { testUserToken } from '../../../API/API';
import { RiHistoryFill } from 'react-icons/ri';
import { fetchAdminPublicInfo } from '../../Copy-pastes/API';
import { format } from 'date-fns';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { getPublicHistory } from '../../Dashboard/API';
import { formatUTCtoArgentinaTime } from './utils/utils';

export const NewUser = (props) => {

    // const adminName = process.env.REACT_APP_CAJERO
    const [adminName, setAdminName] = useState(sessionStorage.getItem('ownerName'));

    const navigate = useNavigate(); // Hook para manejar la navegación

    const [form, setForm] = useState({ name: '' })
    const [loading, setLoading] = useState({ loading: false })
    const [historial, setHistorial] = useState([])
    const historialRef = useRef(null);

    const [mostrarHistorial, setMostrarHistorial] = useState(false);
    const [errorsQuantity, setErrorQuantities] = useState(0)

    const [messagesNewUser, setMessagesNewUser] = useState([])
    const [messagesUnblock, setMessagesUnblock] = useState([])
    const [messagesReset, setMessagesReset] = useState([])
    const [messagesClone, setMessagesClone] = useState([])
    const [dropdownOpen, setDropdownOpen] = useState(false)
    // const firstLetterForUsers = process.env.REACT_APP_PRIMERALETRA || "M"
    // game history modal
    const [showGameHistoryModal, setShowGameHistoryModal] = useState(false)
    const [gameHistoryData, setGameHistoryData] = useState([])
    const [firstLetterForUsers, setFirstLetterForUsers] = useState(props?.ownerInfo?.firstLetter ?? '');

    const [adminInfo, setAdminInfo] = useState({})
    const [platform, setPlatform] = useState('');
    const [urlPage, setUrlPage] = useState('');

    useEffect(() => {
        getFirstLetter()
        setMessages()
        getPublicAdminInfo();
    }, [])

    const getFirstLetter = async () => {
        let test = await testUserToken();
        if (test?.data?.status) {
            setPlatform(test.data.user.owner.platform)
            setUrlPage(test.data.user.owner.pageLink)
            setFirstLetterForUsers(test.data.user.owner.firstLetter)
        }
    }

    const getPublicAdminInfo = async () => {
        const info = await fetchAdminPublicInfo()
        if (info) {
            setAdminInfo(info)
        }
    }

    const setMessages = async () => {
        let config = getApiConfig()

        // await axios.post('/getStaticButton', { name: 'static_clone', owner: adminName }, config)
        //     .then(res => setMessagesClone(res))

        await axios.post('/getStaticButton', { name: 'static_unblock', owner: adminName }, config)
            .then(res => setMessagesUnblock(res))

        await axios.post('/getStaticButton', { name: 'static_reset', owner: adminName }, config)
            .then(res => setMessagesReset(res))

        await axios.post('/getStaticButton', { name: 'static_newuser', owner: adminName }, config)
            .then(res => setMessagesNewUser(res))
    }


    //Handlers 

    const handleNewUserSuccess = async (user) => {
        await navigator.clipboard.writeText(createUser_success(user, props.fetchedData))
    }

    const handleUnblockUser = async (user) => {
        await navigator.clipboard.writeText(unblock_success(user))
    }

    const handleResetSuccess = async (user) => {
        await navigator.clipboard.writeText(resetpw_success(user))
    }

    const handleLoader = () => {
        setLoading(true)
    }

    const handleCloneUserSuccess = async (user) => {
        await navigator.clipboard.writeText(cloneUser_success(user))
    }

    const nameHandler = async (e) => {
        if (e.target.value.trim().length > 16) { Swal.fire('El nombre de usuario no puede tener más de 16 carácteres.'); return }
        if (!(/^[A-Za-z0-9]*$/.test(e.target.value.trim()))) { Swal.fire('El nombre no puede contener carácteres especiales. Solo letras y números'); return }
        setForm({ ...form, name: e.target.value.replace(' ', '').trim() })
    }

    const getTime = () => {
        let d = new Date();
        let hora = d.getHours();
        let minutos = d.getMinutes();
        if (hora < 10) hora = "0" + hora;
        if (minutos < 10) minutos = "0" + minutos;
        return (hora + ":" + minutos)
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleRedirectToGameHistory = () => {

        if (form.name.length > 16) { Swal.fire('El usuario no puede tener más de 16 letras. Verifica que esté bien.'); return }
        if (form.name.length < 3) { Swal.fire({ text: 'Ingresa al menos tres letras.' }); return }
        if (!loading) { return }

        if (platform === 'megafaraon') {
            return navigate(`/gameHistoryMegafaraon?user=${form.name}`);
        }
        if (platform === 'ganamos') {
            return navigate(`/gameHistoryGanamos?user=${form.name}`);
        }
        navigate(`/gameHistory?user=${form.name}`);
    }

    const handleCopyFromHistory = (record) => {
        let text;

        if (record.type === 'Crear') text = `Usuario ${record.user} creado`;
        if (record.type === 'Desbloquear') text = `Usuario ${record.user} desbloqueado`;
        if (record.type === 'Reestablecer') text = `Contraseña de usuario ${record.user} reestablecida.`;

        Swal.fire({
            text: record.success ? text : record.info,
            icon: record.success ? `success` : `error`,
            showConfirmButton: record.success ? true : false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Copiar mensaje'
        }).then((e) => {
            if (e.isConfirmed && record.type === 'Crear') { handleNewUserSuccess(record.user) }
            if (e.isConfirmed && record.type === 'Desbloquear') { handleUnblockUser(record.user) }
            if (e.isConfirmed && record.type === 'Reestablecer') { handleResetSuccess(record.user) }
        })
    }


    // Ilumination on success/failed POST!

    function iluminateBorderSuccess() {
        let div = document.getElementById("historial_users");
        if (div) {
            div.classList?.add("success");
            setTimeout(function () {
                div.classList?.remove("success");
            }, 450);
        }
    }

    // History
    const toggleMostrarHistorial = () => {
        setErrorQuantities(0)
        setMostrarHistorial(!mostrarHistorial);
        historialRef.current.scrollTop = historialRef.current.scrollHeight - historialRef.current.clientHeight;
    }

    const getHistoryColor = (e) => {
        if (e[3] === 'Bonificacion') { return 'orange' }
        if (parseFloat(e[4]?.replace(",", ".")) < 0) return 'red'
        if (parseFloat(e[4]?.replace(",", ".")) > 0) return 'green'
        // return parseFloat(e[4].replace(",", ".")) < 0;
    }


    const getHistoryColorPulpobet = (e) => {
        if (e?.operationType === 'WITHDRAWAL') { return 'red' }
        if (e?.operationType === 'DEPOSIT') { return 'green' }
        return '';
    }
    const getTruncatedAmountPulpobet = (e) => {
        const truncatedAmount = Math.floor(e?.amount / 100);
        return truncatedAmount;
    }
    const getTransactionNamePulpobet = (e) => {
        if (e?.operationType === 'WITHDRAWAL') { return 'Descarga' }
        if (e?.operationType === 'DEPOSIT') { return 'Carga' }
        return '';
    }


    const convertDateToArgentinaTime = (dateString) => {
        if (!dateString) return '';
        const timeZone = 'America/Argentina/Buenos_Aires';
        const argentinaDate = toZonedTime(dateString, timeZone);
        return formatInTimeZone(argentinaDate, timeZone, 'yyyy-MM-dd HH:mm:ssXXX');
    }
    const getHistoryColorMegaFaraon = (e) => {
        if (e?.valor > 0) { return 'green' }
        if (e?.valor < 0) { return 'red' }
        return '';
    }


    const getHistoryColorGanamos = (e) => {
        if (e?.operation === 1) { return 'red' }
        if (e?.operation === 0) { return 'green' }
        if (e?.operation === 2) { return 'orange' }
        return '';
    }

    const getHistoryLabelGanamos = (e) => {
        if (e?.operation === 1) { return 'Descarga' }
        if (e?.operation === 0) { return 'Carga' }
        if (e?.operation === 2) { return 'Bonificación' }
        return '';
    }

    const handleUserChargesHistory = async (historyArray, currentAmmount) => {


        if (platform === 'ganamos') {
            console.log(historyArray, 'historyArray')
            let history = historyArray?.result?.transfers ?? [];

            Swal.fire({
                html:
                    `<div class="my-custom-class">
                            <h6 style="color:grey">Historial de cargas/retiros del usuario ${form.name} </h6>
                            <span>(última semana)</span>
                            <br/>
                            <br/>
                            ${currentAmmount && `<h3><span>El usuario tiene ${currentAmmount} fichas.</span></h3></br>`}
                            <span>- Historial:</span><br/>
                            <br/>
                            ${history?.map(e =>
                        `<span style="color:${getHistoryColorGanamos(e)}">[${formatUTCtoArgentinaTime(e?.created_at)}] ${e?.to_user} ${e?.amount} (${getHistoryLabelGanamos(e)})</span><br/>`
                    ).join('')
                    }

                        </div > `,
                background: '#495057',
                showConfirmButton: false,
                customClass: {
                    popup: 'bg-dark',
                    title: 'text-light',
                    htmlContainer: 'text-light',
                    confirmButton: 'bg-primary',
                    cancelButton: 'bg-danger'
                }
            });

            return;
        }

        if (platform === 'pulpobet') {
            Swal.fire({
                html:
                    `<div class="my-custom-class">
                            <h6 style="color:grey">Historial de cargas/retiros del usuario ${form.name} (últimos 30 días)</h6>
                            ${currentAmmount && `<h3><span>El usuario tiene ${currentAmmount} fichas.</span></h3></br>`}
                            <span>- Historial:</span><br/>
                            <br/>
                            ${historyArray?.agentPaymentChipRowReport?.map(e =>
                        `<span style="color:${getHistoryColorPulpobet(e)}">[${e?.operationDate?.replace('T', ' ')}] ${getTruncatedAmountPulpobet(e)} <span style="color:gray">( ${getTransactionNamePulpobet(e)} )</span></span><br/>`
                    ).join('')
                    }
                        </div > `,
                background: '#495057',
                showConfirmButton: false,
                customClass: {
                    popup: 'bg-dark',
                    title: 'text-light',
                    htmlContainer: 'text-light',
                    confirmButton: 'bg-primary',
                    cancelButton: 'bg-danger'
                }
            });

            return;
        }
        if (platform === 'megafaraon') {
            Swal.fire({
                html:
                    `<div class="my-custom-class">
                            <h6 style="color:grey">Historial de cargas/retiros del usuario ${form.name} (últimos 30 días)</h6>
                            ${currentAmmount && `<h3><span>El usuario tiene ${currentAmmount} fichas.</span></h3></br>`}
                            <span>- Historial:</span><br/>
                            <br/>
                            ${historyArray?.data?.map(e =>
                        `<span style="color:${getHistoryColorMegaFaraon(e)}">[${convertDateToArgentinaTime(e?.fecha)}] ${e?.valor} <span style="color:gray">( ${e?.detalles} )</span></span><br/>`
                    ).join('')
                    }
                        </div > `,
                background: '#495057',
                showConfirmButton: false,
                customClass: {
                    popup: 'bg-dark',
                    title: 'text-light',
                    htmlContainer: 'text-light',
                    confirmButton: 'bg-primary',
                    cancelButton: 'bg-danger'
                }
            });

            return;
        }


        Swal.fire({
            html:
                `<div class="my-custom-class">
                        <h6 style="color:grey">Historial de cargas/retiros del usuario ${form.name}</h6>
                        <h3><span>El usuario tiene ${currentAmmount} fichas.</span></h3></br >
                        <span>- Historial:</span><br/>
                        <br/>
                        ${historyArray?.data?.map(e =>
                    `<span style="color:${getHistoryColor(e)}">[${e[0]}] ${e[4]} <span style="color:gray">( ${e[3]} )</span></span><br/>`
                ).join('')
                }
                    </div> `,
            background: '#495057',
            showConfirmButton: false,
            customClass: {
                popup: 'bg-dark',
                title: 'text-light',
                htmlContainer: 'text-light',
                confirmButton: 'bg-primary',
                cancelButton: 'bg-danger'
            }
        });
    }


    const handleUserChargesGameHistory = (historyArray, currentAmmount) => {
        setDropdownOpen(false)
        setGameHistoryData(historyArray)
        setShowGameHistoryModal(true)
    }



    // • Functions • 
    // ----------------------------------------------------
    // --------------------------  GET USER HISTORY
    // ----------------------------------------------------
    // const today = new Date();

    // const formatDateN = (date) => {
    //     const months = [
    //         'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
    //         'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    //     ];

    //     // Ajustar la fecha actual a la zona horaria de Argentina
    //     const argDate = new Date(date.toLocaleString("en-US", { timeZone: "America/Argentina/Buenos_Aires" }));
    //     argDate.setDate(argDate.getDate() + 2); // Sumar 1 dia

    //     const day = argDate.getDate();
    //     const month = months[argDate.getMonth()];
    //     const year = argDate.getFullYear();

    //     return `${ day } +${ month } +${ year } `;
    // }

    const getUserHistory = async () => {
        if (form.name.length > 16) { Swal.fire('El usuario no puede tener más de 16 letras. Verifica que esté bien.'); return }
        if (form.name.length < 3) { Swal.fire({ text: 'Ingresa al menos tres letras.' }); return }
        if (!loading) { return }

        let history = []
        let currentAmmount = ''
        setLoading(false)
        setDropdownOpen(false)
        // const endDateString = formatDateN();
        try {
            let config = getApiConfig()
            await axios.post(`/historyC`, { name: form.name, adminName: adminName }, config)
                .then((result) => {
                    if (result?.data.TotalAmount) { currentAmmount = result?.data.TotalAmount }
                    history = result.data
                    setLoading(true)
                    if (typeof result.data == 'string' && result?.data?.includes('ERROR')) Swal.fire({ text: result.data });
                })

        } catch (error) {
            console.log(error)
            Swal.fire({ text: `Ha habido un error.Contacta al administrador.` });
            setLoading(true)

        }
        if (typeof history !== 'string') handleUserChargesHistory(history, currentAmmount)
        setLoading(true)

    }

    // ----------------------------------------------------
    // --------------------------  NEW USER
    // ----------------------------------------------------


    const createNewPlayerPromo = async (full_user) => {
        try {
            let config = getApiConfig()
            await axios.post(`/createUserInPromo`, { platformUser: `${firstLetterForUsers}${full_user}` }, config)
                .then(() => {
                    console.log('ok')
                })

        } catch (error) {
            console.log(error)
        }
    }


    const newUser = async (e, createdInPromo = false) => {
        e.preventDefault();

        if (platform === 'pulpobet') {
            if (form.name.length < 4) { Swal.fire({ text: 'Ingresa al menos cuatro letras.' }); return }
            if (form.name.length > 12) { Swal.fire('Máximo 12 carácteres para crear usuarios.'); return }
        }

        if (form.name.length > 13) { Swal.fire('Máximo 13 carácteres para crear usuarios.'); return }
        if (form.name.length < 3) { Swal.fire({ text: 'Ingresa al menos tres letras.' }); return }

        if (!loading) { return }




        setLoading(false)
        if (props.fastMode) { setTimeout(handleLoader, 3000) }
        let noRandomNumber = props?.ownerInfo?.id === "8d317885-e8da-4c5d-ad3d-640ae99bcc76";
        let randomN = noRandomNumber ? '' : Math.floor(Math.random() * (99 - 1) + 1);
        let full_user = form.name.replace(' ', '').trim() + randomN

        setForm({ name: '' })


        if (createdInPromo) {
            createNewPlayerPromo(full_user)
        }

        // handleNewUserSuccess(firstLetterForUsers + full_user)

        const copyMessage = async (text, name) => {
            await navigator.clipboard.writeText(text)
            let updatedMessage = text
                .replaceAll('__USUARIO__', firstLetterForUsers + name)
                .replaceAll('__PASSWORD__', adminInfo?.default_users_password ?? 'hola123')
                .replaceAll('__LINK__', urlPage)


            await navigator.clipboard.writeText(updatedMessage);
        }



        // let messages = await axios.post('/getStaticButton', { name: 'static_add', owner: 'platcas' })
        let randomIndex = Math.floor(Math.random() * messagesNewUser?.data?.Messages.length);
        let randomValue = messagesNewUser?.data?.Messages[randomIndex];
        if (randomValue) copyMessage(randomValue.text, full_user)

        try {
            let config = getApiConfig()
            await axios.post(`/createC`, { name: `${firstLetterForUsers}${full_user}`, password: adminInfo?.default_users_password ?? 'hola123', adminName: adminName, loggedUser: props?.userLoggedName }, config)
                .then((result) => {
                    if (!result.data.includes('ERROR')) {
                        let record = { user: full_user, hour: getTime(), success: true, type: 'Crear' }
                        setHistorial([...historial, record])
                        iluminateBorderSuccess()
                    } else {
                        let record = { user: full_user, hour: getTime(), success: false, type: 'Crear', info: result.data }
                        setHistorial([...historial, record])
                        if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)
                        Swal.fire({ icon: 'error', text: result.data });
                        // props?.checkTokenValidity();
                    }
                })
            if (!props.fastMode) { setLoading(true) }

        } catch (error) {
            console.log(error)
            if (!props.fastMode) { setLoading(true) }
            Swal.fire({ text: `Ha habido un error.Chequea los logs.` });
        }
    }

    const handleCreateUserInPromo = async () => {
        const mockSubmitEvent = new Event('submit', { bubbles: true, cancelable: true })
        newUser(mockSubmitEvent, true)
    }




    // ----------------------------------------------------
    // --------------------------  CLONE USER
    // ----------------------------------------------------

    const cloneUser = async (e) => {
        e.preventDefault();
        if (form.name.length > 16) { Swal.fire('Máximo 13 carácteres para crear usuarios.'); return }
        if (form.name.length < 3) { Swal.fire({ text: 'Ingresa al menos tres letras.' }); return }
        if (!loading) { return }


        setForm({ name: '' })
        setLoading(false)
        if (props.fastMode) { setTimeout(handleLoader, 3000) }

        let randomN = Math.floor(Math.random() * (99 - 1) + 1);

        let firstModification = form.name.replace(' ', '').trim()
        let full_user = firstModification[0] === 'B' || firstModification[0] === 'b' ? firstModification.slice(1, 16) : firstModification.slice(0, 15)



        const copyMessage = async (text, newuser) => {
            let updatedMessage = text
                .replaceAll('__USUARIO__', (form.name))
                .replaceAll('__NEWNAME__', firstLetterForUsers + full_user)
                .replaceAll('__LINK__', urlPage)
                .replaceAll('__PASSWORD__', adminInfo?.default_users_password ?? 'hola123')

            await navigator.clipboard.writeText(updatedMessage);
        }
        // let messages = await axios.post('/getStaticButton', { name: 'static_clone', owner: 'platcas' })
        let randomIndex = Math.floor(Math.random() * messagesClone?.data?.Messages.length);
        let randomValue = messagesClone?.data?.Messages[randomIndex];
        if (randomValue) copyMessage(randomValue.text, form.name)

        // handleCloneUserSuccess("M" + full_user)


        try {
            let config = getApiConfig()
            await axios.post(`/createC`, { name: firstLetterForUsers + full_user, password: 'hola123', adminName: adminName, loggedUser: props?.userLoggedName }, config)
                .then((result) => {
                    if (!result.data.includes('ERROR')) {
                        let record = { user: firstLetterForUsers + full_user, hour: getTime(), success: true, type: 'Clonar' }
                        setHistorial([...historial, record])
                        iluminateBorderSuccess()
                    } else {
                        let record = { user: full_user, hour: getTime(), success: false, type: 'Clonar', info: result.data }
                        setHistorial([...historial, record])
                        if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)

                        Swal.fire({ icon: 'error', text: result.data });
                        // props?.checkTokenValidity();

                    }
                })
            if (!props.fastMode) { setLoading(true) }

        } catch (error) {
            console.log(error)
            if (!props.fastMode) { setLoading(true) }
            Swal.fire({ text: `Ha habido un error.Chequea los logs.` });
        }
    }


    // ----------------------------------------------------
    // --------------------------  UNBLOCK USER
    // ----------------------------------------------------

    const unblockUser = async (e) => {

        e.preventDefault();


        if (form.name.length < 3) { Swal.fire({ text: 'Para desbloquear ingresa el nombre de usuario completo en el campo de arriba' }); return }
        if (!loading) { return }


        // todo esto es para copiar mensajes con variantes
        const copyMessage = async (text, name) => {
            await navigator.clipboard.writeText(text)
            let updatedMessage = text
                .replaceAll('__USUARIO__', (form.name))
            await navigator.clipboard.writeText(updatedMessage);
        }
        // let messages = await axios.post('/getStaticButton', { name: 'static_unblock', owner: 'platcas' })
        let randomIndex = Math.floor(Math.random() * messagesUnblock?.data?.Messages.length);
        let randomValue = messagesUnblock?.data?.Messages[randomIndex];
        if (randomValue) copyMessage(randomValue.text, form.name)



        let originalName = form.name

        setForm({ name: '' })
        setLoading(false)
        if (props.fastMode) { setTimeout(handleLoader, 3000) }

        // handleUnblockUser(form.name)

        try {
            let config = getApiConfig()
            await axios.post(`/unlockC`, { name: form.name, adminName: adminName, loggedUser: props?.userLoggedName }, config)
                .then((result) => {
                    if (result.status === 200) {
                        let record = { user: originalName, hour: getTime(), success: true, type: 'Desbloquear' }
                        setHistorial([...historial, record])
                        iluminateBorderSuccess()
                    } else {
                        let record = { user: originalName, hour: getTime(), success: false, type: 'Desbloquear', info: result.data }
                        setHistorial([...historial, record])
                        if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)
                        Swal.fire({ icon: 'error', text: result.data });
                        // props?.checkTokenValidity();
                    }
                })

        } catch (error) {
            let errorMessage = 'ERROR: No se ha podido desbloquear, el usuario ya está desbloqueado o no existe.'
            if (error?.response?.data?.msg) {
                errorMessage = error.response.data.msg
            }
            let record = { user: originalName, hour: getTime(), success: false, type: 'Desbloquear', info: 'ERROR: No se ha podido desbloquear' }
            setHistorial([...historial, record])
            if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)
            Swal.fire({ icon: 'error', text: errorMessage });
            if (!props.fastMode) { setLoading(true) }
            setForm({ name: '', quantity: '' })
            if (!props.fastMode) { setLoading(true) }

        }
        if (!props.fastMode) { setLoading(true) }

    }

    // ----------------------------------------------------
    // --------------------------  RESET PASSWORD
    // ----------------------------------------------------

    const resetPw = async (e) => {

        e.preventDefault();
        if (form.name.length < 3) { Swal.fire({ text: 'Para desbloquear ingresa el nombre de usuario completo en el campo de arriba' }); return }
        if (!loading) { return }


        const copyMessage = async (text, name) => {
            await navigator.clipboard.writeText(text)
            let updatedMessage = text
                .replaceAll('__USUARIO__', (form.name))
                .replaceAll('__PASSWORD__', adminInfo?.default_users_password ?? 'hola123')
                .replaceAll('__LINK__', urlPage)


            await navigator.clipboard.writeText(updatedMessage);
        }
        // let messages = await axios.post('/getStaticButton', { name: 'static_reset', owner: 'platcas' })
        let randomIndex = Math.floor(Math.random() * messagesReset?.data?.Messages.length);
        let randomValue = messagesReset?.data?.Messages[randomIndex];
        if (randomValue) copyMessage(randomValue.text, form.name)


        setForm({ name: '' })
        setLoading(false)
        if (props.fastMode) { setTimeout(handleLoader, 3000) }

        // handleResetSuccess(form.name)
        let originalName = form?.name?.trim()
        try {
            let config = getApiConfig()
            await axios.post(`/resetC`, { name: form.name, adminName: adminName, loggedUser: props?.userLoggedName }, config)
                .then((result) => {
                    // eslint-disable-next-line eqeqeq
                    if (result?.status == 200 && result?.data) {
                        let record = { user: originalName, hour: getTime(), success: true, type: 'Reestablecer' }
                        setHistorial([...historial, record])
                        iluminateBorderSuccess()
                    } else {
                        let record = { user: originalName, hour: getTime(), success: false, type: 'Reestablecer', info: `El usuario no existe, o su contraseña ya es ${adminInfo?.default_users_password ?? "hola123"}` }
                        setHistorial([...historial, record])
                        if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)
                        Swal.fire({ icon: 'error', text: `El usuario no existe. O si existe, su contraseña ya es ${adminInfo?.default_users_password ?? "hola123"}` });
                        // props?.checkTokenValidity();
                    }

                })
            if (!props.fastMode) { setLoading(true) }

        } catch (error) {
            if (!props.fastMode) { setLoading(true) }
            let errorMessage = 'ERROR: No se ha podido desbloquear, el usuario ya está desbloqueado o no existe.'
            if (error?.response?.data?.msg) {
                errorMessage = error.response.data.msg
            }
            let record = { user: originalName, hour: getTime(), success: false, type: 'Reestablecer', info: errorMessage }
            setHistorial([...historial, record])
            if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)
            Swal.fire({ icon: 'error', text: errorMessage });
            if (!props.fastMode) { setLoading(true) }
            setForm({ name: '', quantity: '' })
            console.log(error)
        }
    }



    // ----------------------------------------------------
    // --------------------------  HTML
    // ----------------------------------------------------

    return (
        <>
            {showGameHistoryModal &&
                <GameHistoryModal
                    loading={loading}
                    adminName={adminName}
                    setLoading={setLoading}
                    form={form}
                    handleClose={() => setShowGameHistoryModal(false)}
                    userName={form.name}
                    showModal={showGameHistoryModal}
                    historyArray={gameHistoryData}>
                </GameHistoryModal>
            }



            <div className='newUser_container mx-3 py-2 mt-2'>


                {/* <div className='title-container'><small>Panel de usuarios</small></div> */}

                <form className='mx-5 px-4 m-auto'>
                    <div className="my-2 text-white d-flex justify-content-start" style={{ fontSize: '14px' }}>
                        Panel de Usuarios
                    </div>
                    <div className="input-group mb-3">
                        <input value={form.name} onChange={nameHandler} type="text" className="form-control form-control customDarkInput" placeholder="Username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <div className="input-gro-append" title='Crear usuario'>
                            <button disabled={!loading} onClick={newUser} style={{ minWidth: "10%" }} className="btn btn-sm btn-green text-black" type="submit">{loading ? <BsPersonFillAdd size={20} className='p-0 m-0'></BsPersonFillAdd > : 'Loading..'}</button>
                        </div>

                        {adminInfo?.currentlyInPromo &&
                            <div className="input-gro-append" title='Crear usuario en Promoción'>
                                <button
                                    disabled={!loading}
                                    style={{ minWidth: "10%" }}
                                    className="btn btn-sm btn-green text-black mx-1 text-white"
                                    type="button"
                                    onClick={handleCreateUserInPromo}
                                >
                                    {loading ? <BiGift size={20} className='p-0 m-0' /> : 'Loading..'}
                                </button>
                            </div>
                        }
                    </div>
                </form>

                {loading ?

                    (
                        <>
                            <Row className='mx-5 w-100 m-auto text-white pb-2 px-3' style={{ fontSize: '0.7rem' }}>
                                <Col onClick={unblockUser} className='handlerButton p-2 mx-1 rounded'>
                                    <FiUnlock size={20}></FiUnlock>
                                    <Row className='d-flex justify-content-center'><span className='text-center'>Desbloquear</span></Row>
                                </Col>
                                <Col onClick={resetPw} className='handlerButton p-2 mx-1 rounded'>
                                    <FiRefreshCcw size={20}></FiRefreshCcw>
                                    <Row className='d-flex justify-content-center'><span className='text-center'>Reestablecer</span></Row>
                                </Col>
                                {/* <Col onClick={cloneUser} className='handlerButton mx-1 px-2 rounded'>
                                    <Row className='d-flex justify-content-center'><span className='text-center'>Clonar</span></Row>
                                    <FiUsers size={30}></FiUsers>
                                </Col> */}

                                <Col className='handlerButton mx-1 p-2 rounded'>
                                    <div onClick={toggleDropdown}>
                                        <RiHistoryFill size={20} />
                                        <Row className='d-flex justify-content-center'>
                                            <span className='text-center'>{loading ? 'Historial' : ''}</span>
                                        </Row>
                                    </div>

                                    <Dropdown show={dropdownOpen}>
                                        <Dropdown.Menu className='dark-dropdown-custom'>
                                            <Dropdown.Item className="text-secondary" onClick={getUserHistory} >Historial de cargas</Dropdown.Item>
                                            <Dropdown.Item className="text-secondary" >
                                                <div onClick={handleRedirectToGameHistory}>
                                                    Historial de juego
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>


                                {/* <Col onClick={getUserHistory} title='Mostrar cargas y retiros de los últimos 3 días.' className='handlerButton mx-1 px-2 rounded'>
                <Row className='d-flex justify-content-center'><span className='text-center'>Historial</span></Row>
                <MdOutlineHistoryToggleOff size={30}></MdOutlineHistoryToggleOff>
            </Col> */}
                            </Row>
                        </>
                    )
                    :
                    (
                        <>
                            <Row className='mx-5 w-75 m-auto' style={{ fontSize: '0.7rem' }}>

                                <Col className='handlerButton mx-1 px-2 rounded subtractButton'>
                                    <Row className='d-flex justify-content-center'><span className='text-center'>{loading ? '' : 'Loading..'}</span></Row>
                                    <BiLoaderCircle size={30}></BiLoaderCircle>
                                </Col>
                                <Col className='handlerButton mx-1 px-2 rounded subtractButton'>
                                    <Row className='d-flex justify-content-center'><span className='text-center'>{loading ? '' : 'Loading..'}</span></Row>
                                    <BiLoaderCircle size={30}></BiLoaderCircle>
                                </Col>
                                {/* < Col className='handlerButton mx-1 px-2 rounded subtractButton'>
                                    <Row className='d-flex justify-content-center'><span className='text-center'>{loading ? '' : 'Loading..'}</span></Row>
                                    <BiLoaderCircle size={30}></BiLoaderCircle>
                                </Col> */}

                                <Col className='handlerButton mx-1 px-2 rounded subtractButton'>
                                    <Row className='d-flex justify-content-center'><span className='text-center'>{loading ? '' : 'Loading..'}</span></Row>
                                    <BiLoaderCircle size={30}></BiLoaderCircle>
                                </Col>

                            </Row>
                        </>
                    )
                }

                {historial.length > 0 &&
                    <>
                        <div id='historial_users' className={`historial_container mt-2 position-relative  ${mostrarHistorial ? 'mostrar-historial' : ' border-0'} `}>
                            {mostrarHistorial && <button
                                className='btn btn-dark p-0 px-1 eye'
                                title='Ocultar historial'
                                type="button"
                                onClick={toggleMostrarHistorial}
                            >
                                <FontAwesomeIcon
                                    icon={mostrarHistorial && faEye}
                                />
                            </button>}


                            <Col className='text-center fullhistorial pointer'>
                                {mostrarHistorial ?

                                    (historial.map(record => (
                                        <Row className={`d-flex justify-content-center ${record.success ? 'text-success' : 'text-danger'} `}>
                                            <span title={record.info} className='text-center' style={{ fontSize: '12px' }}>
                                                <span className='historyHour' onClick={() => handleCopyFromHistory(record)}>[{record.hour}]</span>
                                                {record.type} usuario {record.type === 'Crear' && firstLetterForUsers}{record.user}: {record.success ? 'hecho' : 'fallo'}
                                            </span>
                                        </Row>
                                    )))
                                    :
                                    <div className="text-secondary showHistory" style={{ fontSize: '12px' }} onClick={toggleMostrarHistorial}
                                    >
                                        {errorsQuantity > 0 ? <span className='text-danger'>Tenés {errorsQuantity} errores sin ver</span> : "Mostrar historial"}
                                    </div>
                                }
                            </Col>
                        </div>
                    </>
                }

            </div>

        </>

    )
}
