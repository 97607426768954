import axios from 'axios'
import { getApiConfig } from '../../API/getConfig';

export const fetchButton = async (buttonId) => {
    const config = getApiConfig()
    let result;
    try {
        await axios.get(`/admin/getButton?id=${buttonId}`, config)
            .then((res) => {
                result = res.data || []
                return result
            })
    } catch (error) {
        console.log(error)
    }

    return result
}
export const fetchButtonAdmin = async (buttonId) => {
    const config = getApiConfig()
    let result;
    try {
        await axios.get(`/admin/getButtonAdmin?id=${buttonId}`, config)
            .then((res) => {
                result = res.data || []
                return result
            })
    } catch (error) {
        console.log(error)
    }

    return result
}


export const editMessage = async (messageId, text) => {
    let result;
    const config = getApiConfig()

    try {
        await axios.post(`/admin/editMessage`, { messageId: messageId, text: text }, config)
            .then((res) => {
                result = res.data || []
                return result
            })
    } catch (error) {
        console.log(error)
    }

    return result
}

export const createButton = async (body) => {

    //type: DataTypes.ENUM('bienvenida', 'info', 'premios', 'fichas', 'extra'),
    //body example: {name: 'cbu', messages: [text1, text2, text3], category: 'premio'}
    const config = getApiConfig()

    let result;
    try {
        await axios.post(`/admin/createButton`, body, config)
            .then((res) => {
                result = res.data || []
                return result
            })
    } catch (error) {
        console.log(error)
    }

    return result
}

export const createVariant = async (body) => {
    //expect text & buttonId
    const config = getApiConfig()

    let result
    try {
        await axios.post(`/admin/createVariant`, body, config)
            .then((res) => {
                result = res.data || []
                return result
            })
    } catch (error) {
        console.log(error)
    }
}


export const deleteVariant = async (variantId) => {
    const config = getApiConfig()

    let result
    try {
        await axios.post(`/admin/deleteMessage`, { messageId: variantId }, config)
            .then((res) => {
                result = res.data || []
                return result
            })
    } catch (error) {
        console.log(error)
    }
}

export const deleteButton = async (buttonId) => {
    const config = getApiConfig()

    let result
    try {
        await axios.post(`/admin/deleteButton`, { buttonId: buttonId }, config)
            .then((res) => {
                result = res.data || []
                return result
            })
    } catch (error) {
        console.log(error)
    }
}

export const getAllHistory = async (adminName, creatorName, targetParam, startDate, endDate, page = 1, pageSize = 10,) => {
    const config = getApiConfig();
    try {
        // Construir la URL con los parámetros de consulta
        const url = `/history?page=${page}&pageSize=${pageSize}${creatorName ? `&creatorName=${creatorName}` : ''}${targetParam ? `&targetName=${targetParam}` : ''}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;

        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getAllHistoryExport = async (adminName, creatorName, targetParam, startDate, endDate, page = 1, pageSize = 10,) => {
    const config = getApiConfig();
    try {
        // Construir la URL con los parámetros de consulta
        const url = `/history/export?page=${page}&pageSize=${pageSize}${creatorName ? `&creatorName=${creatorName}` : ''}${targetParam ? `&targetName=${targetParam}` : ''}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;

        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const fetchButtonsAdmin = async () => {
    let config = getApiConfig()
    let result;
    try {
        await axios.get(`/admin/getButtonsAdmin`, config)
            .then((res) => {
                result = res.data || []
            })
    } catch (error) {
        console.log(error)
    }

    return result
}

export const testAdminToken = async () => {
    const config = getApiConfig()
    try {
        let result = await axios.post(`/admin/testAdminToken`, {}, config)
        return result;
    } catch (error) {
        console.log(error)
        return error
    }
}


export const getNotificationsAdmin = async () => {
    let config = getApiConfig()
    let result;
    try {
        await axios.get(`/admin/getNotificationsAdmin`, config)
            .then((res) => {
                result = res.data || []
            })
    } catch (error) {
        console.log(error)
    }

    return result
}

export const getBotUsers = async () => {
    let config = getApiConfig()
    let result;
    try {
        await axios.get(`/admin/getBotUsers`, config)
            .then((res) => {
                result = res.data || []
            })
    } catch (error) {
        console.log(error)
    }

    return result
}


export const getAdminHistory = async () => {
    let config = getApiConfig()
    let result;
    try {
        await axios.get(`/admin/getBotUsers`, config)
            .then((res) => {
                result = res.data || []
            })
    } catch (error) {
        console.log(error)
    }

    return result
}


export const getOwnerInfo = async () => {
    let config = getApiConfig()
    let result;
    try {
        await axios.get(`/admin/getOwnerData`, config)
            .then((res) => {
                result = res
            })
    } catch (error) {
        console.log(error)
    }

    return result;

}

export const getPaymentScale = async () => {
    let config = getApiConfig()
    let result;
    try {
        await axios.get(`/admin/getPaymentScale`, config)
            .then((res) => {
                result = res?.data ?? '';
            })
    } catch (error) {
        console.log(error)
    }

    return result
}


export const editPaymentScale = async (newContent) => {
    const config = getApiConfig()
    const body = {
        content: newContent
    }
    try {
        let result = await axios.post(`/admin/editPaymentScale`, body, config)
        return result;
    } catch (error) {
        console.log(error)
        return error
    }
}

export const getPublicHistory = async (adminName, creatorName, targetParam, startDate, endDate, page = 1, pageSize = 10,) => {
    const config = getApiConfig();
    try {
        // Construir la URL con los parámetros de consulta
        const url = `/publicHistory?page=${page}&pageSize=${pageSize}${creatorName ? `&creatorName=${creatorName}` : ''}${targetParam ? `&targetName=${targetParam}` : ''}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;

        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getPlayers = async ({ page = 1, pageSize = 10, startDate, endDate, createdInPromo }) => {
    const config = getApiConfig()

    try {
        let result = await axios.get(`/admin/players?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&createdInPromo=${createdInPromo}`, config)
        return result.data;
    } catch (error) {
        console.log(error)
        return error
    }
}
